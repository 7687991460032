import React, { useState } from 'react';

import { ProductBox } from '../components/boxen';
import Seo from '../components/seo'

import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'


// page definition ----------------------------------------
const MtoolsPage = () => {
  const [hup, setHup] = useState(true);
  const [info, setInfo] = useState(true);
  const [custom, setCustom] = useState(true);

  function handleClick(id: string) {
    switch(id) {
      case "hup":
        setHup(!hup);
        setInfo(true);
        setCustom(true);
        break;
    case "info":
        setHup(true);
        setInfo(!info);
        setCustom(true);
        break;
    case "custom":
        setHup(true);
        setInfo(true);
        setCustom(!custom);
        break;
    default:
        break;
    }
  }

  return(
  <>
    <Seo title="Mtools - ecom marketplace tools" />
    <h1>Ecom Marketplace Tools</h1>
    <Box border={1} borderRadius={15} px={1}>
      Please note: We are no longer offering downloads of the stand-alone tools for MWS.
        This is due to changes in the Amazon MWS AUP and DPP, which prohibit using your
        developer keys in 3rd party software. An updated, hosted version of the tools
        named <a style={{ color:"#0000ee" }}  href="https://wbench.wernerdigital.com"> wbench </a>
        will be available for beta soon.  We apologize for the inconvenience!
    </Box>
  <Box display="flex" flexWrap="wrap">
  <div style={{ backgroundColor: '#ffffff',
      background: 'linear-gradient(to right,  #F0F8FF, #FAFAFA)'}}>
    <List component="nav">
      <ListItem id="hup" button onClick={() => handleClick("hup")} >
        <ListItemText primary="Heads-up Sales Monitor" />
        {hup ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={!hup} timeout="auto" unmountOnExit>
        <Box display="flex" flexWrap="wrap" flexDirection="row" ml={4}  maxWidth={700}>
          <ProductBox maintitle="multi-channel open orders" maincolor="#F4A460">
             <ListItemText primary="convenience" secondary="check all of your marketplaces at once"/>
          </ProductBox>

          <ProductBox maintitle="display or workstation" maincolor="#ADD8E6">
             <ListItemText primary="security" secondary="no direct user access to marketplace"/>
          </ProductBox>

          <ProductBox maintitle="piclist" maincolor="#9ACD32">
            <ListItemText primary="efficiency" secondary="generate pic list sorted by sku"/>
          </ProductBox>
        </Box>
      </Collapse>

      <ListItem button onClick={() => handleClick("info")}>
        <ListItemText primary="FBA Shipment Scanner" />
        {info ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={!info} timeout="auto" unmountOnExit>
          <Box display="flex" flexWrap="wrap" flexDirection="row" ml={4}  maxWidth={700}>
            <ProductBox maintitle="track multiple boxes/shipments" maincolor="#F4A460">
               <ListItemText primary="convenience" secondary="easily see what is in each box, and what needs to be"/>
            </ProductBox>

            <ProductBox maintitle="display or workstation" maincolor="#ADD8E6">
               <ListItemText primary="security" secondary="no direct user access to marketplace"/>
            </ProductBox>

            <ProductBox maintitle="timestamped doublecheck" maincolor="#9ACD32">
              <ListItemText primary="efficiency" secondary="making sure your shipments are accurate saves time"/>
            </ProductBox>
          </Box>
      </Collapse>

      <ListItem button onClick={() => handleClick("custom")}>
        <ListItemText primary="MWS Report Monitor" />
        {custom ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={!custom} timeout="auto" unmountOnExit>
          <Box display="flex" flexWrap="wrap" flexDirection="row" ml={4}  maxWidth={700}>
            <ProductBox maintitle="see all report requests" maincolor="#F4A460">
               <ListItemText primary="convenience" secondary="find new report names easily"/>
            </ProductBox>

            <ProductBox maintitle="show request history" maincolor="#ADD8E6">
               <ListItemText primary="security" secondary="check 3rd party software report access"/>
            </ProductBox>

            <ProductBox maintitle="show request timing" maincolor="#9ACD32">
              <ListItemText primary="efficiency" secondary="eliminate redundant/too quick requests"/>
            </ProductBox>
          </Box>
      </Collapse>

    </List>

   </div>
  </Box>


  </>
)}

export default MtoolsPage
